<template>
  <div class="legal">
    <div v-if="!showResult">
      <h2>Löschung Ihrer Daten beantragen</h2>
      <p>
        Sie können auf dieser Seite das Löschen Ihrer kompletten Nutzerdaten inkl. aller hochgeladenen Inhalte sowie aller angelegeten Formulare und Benutzer beantragen.<br/>
      </p>
      <p>
        Bitte geben Sie den technischen Namen Ihres Mandanten, den Benutzernamen eines Administratirs und die beim Anlegen Ihres Mandanten generierte "Verbindungsunformation" in das folgende Formular ein und klicken den Knopf zum Starten des Prozesses.<br/>
        <br/>
        <b>Hinweise:</b>
        <ul>
          <li>
            Der technischen Name Ihres Mandanten besteht normalerweise aus zwei kleinen, aneinandergereihten, per Unterstrich ("_") getrennten Worten und einer Zahl am Ende, z.B. (coffee_moore_47)
          </li>
          <li>
            Die "Verbindungsinformation" wurde beim Anlegen Ihres Mandanten erzeugt und kann jederzeit nach Anmeldung im Collada-Portal im Bereich "QR Code" angesehen und in die Zwischenablage kopiert werden.
          </li>
        </ul>
        Unser Team prüft Ihre Angaben und löscht den Mandanten binnen 72 Stunden aus dem System. <br/>
      </p>
      <form action="/requestAccountDeletion">
        <div class="form">
          <div class="row">
            <div class="label">Mandantenname:</div><input type="text" name="tenant" v-model="tenant"/><br/>
          </div>
          <div class="row error" v-if="tenantEmpty">
            Bitte geben Sie Ihren Mandantennamen ein!
          </div>
          <div class="row">
            <div class="label">Verbindungsinformation:</div><textarea type="text" name="info" v-model="info"/><br/>
          </div>
          <div class="row error" v-if="infoEmpty">
            Bitte geben Sie Ihre Verbindungsinformation ein!
          </div>
          <div class="actionButton" @click="requestDeletion()">Kontolöschung beantragen</div>
        </div>
      </form>
    </div>
  </div>
  <div v-if="showResult">
    <div v-if="error" v-html="error"></div>
    <div v-else><div v-html="message"></div></div>
  </div>
  <ConfirmModal ref="confirmDeleteModal" title="Kontolöschung bestätigen" text="Möchten Sie Ihren kompletten Mandaten wirklich löschen? Diese Aktion kann NICHT rückgängig gemacht werden! Alle anelgeten Formulare, Benutzer, Kontakte sowie alle hochgeladenen Berichte werden gelöscht. Fortfahren?"/>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ServiceHelper from '@/helpers/ServiceHelper';

export default {
  name: 'AccountDeletionComponent',
  components: {
    ConfirmModal,
  },
  data() {
    return {
      tenantEmpty: false,
      infoEmpty: false,
      tenant: '',
      info: '',
      showResult: false,
      error: undefined,
      message: '',
    };
  },
  mounted() {
  },
  methods: {
    async requestDeletion() {
      this.infoEmpty = this.info.trim().length === 0;
      this.tenantEmpty = this.tenant.trim().length === 0;

      if (!this.infoEmpty && !this.tenantEmpty && await this.$refs.confirmDeleteModal.show()) {
        try {
          const resp = await ServiceHelper.sendDataToPublicBackend('requestDeletion', { tenant: this.tenant, info: this.info });
          console.log(resp);
          this.showResult = true;
          this.message = `Ihre Anfrage wurde entgegengenommen und wird von unserem Team bearbeitet.<br/>Die Referenz lautet: <b>${resp.payload.ref}</b><br/><br/>`
          + 'Bitte kontaktieren Sie unseren Support unter <a href="mailto:support@collada.app">support@collada.app</a> falls Sie Fragen zu Ihrer Anfrage haben.';
        } catch (err) {
          console.error(err);
          this.showResult = true;
          this.error = `<h2>Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten:</h2> <br/>${err.msg}<br/><br/><br/>Bitte kontaktieren Sie unseren Support unter <a href="mailto:support@collada.app">support@collada.app</a>`;
        }
      }
    },
  },
};
</script>

<style scoped>
  @import url('../styles/legal.css');

  div.legal{
    width: 80%;
  }

  div.form {
    margin: 24px 0px;
    border: 1px solid lightgray;
    padding: 24px;
    border-radius: 24px;
  }

  div.form .row {
    display: flex;
    margin: 16px 0px;
  }

  div.form .row .label {
    flex: 0 250px;
  }
  div.form .row input, div.form .row textarea {
    flex: 1 1;
  }

  div.form .row.error {
    color: red;
    font-size: 0.9em;
    margin-top: 4px;
  }
</style>
